
.App {
    padding: 9px;
    font-size: large;
}

.Body {
    padding: 9px;
}

.m6 {
    width: 6em;
}

.edTable {
    width: 100%;
}

.min {
    width: 1em;
    padding: 2px;
}

.bold {
    font-weight: bold;
}

.TextField {
    margin-bottom: 24px;
    font-size: 1rem;
}






